import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { featureFlags } from './features/feature-flags/feature-flags.reducer';
import { tagging } from './features/tagging/tagging.reducer';
import { tagger } from './features/global-tagger/global-tagger.reducer';
import { onsite } from './features/onsite/onsite.reducer';

const reducer = combineReducers({
    featureFlags,
    tagging,
    tagger,
    onsite,
});

const store = configureStore({
    reducer,
});

export default store;

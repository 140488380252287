export const TYPES = {
    SET_USE_QA_PV_CALCULATOR: 'SET_USE_QA_PV_CALCULATOR',
    SET_USE_NEW_USAGE_MODEL: 'SET_USE_NEW_USAGE_MODEL',
};

export function setUseQAPVCalculator(useQAPVCalculator: boolean) {
    return { type: TYPES.SET_USE_QA_PV_CALCULATOR, useQAPVCalculator };
}

export function setUseNewUsageModel(useNewUsageModel: boolean) {
    return { type: TYPES.SET_USE_NEW_USAGE_MODEL, useNewUsageModel };
}

import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { Button, Checkbox, Grid } from '@mui/joy';
import Input from '@mui/joy/Input';
import SearchIcon from '@mui/icons-material/Search';
import { connect } from 'react-redux';
import useGoogleMapsApiLoader from '../../../infrastructure/google/useGoogleMapsApiLoader';
import { useTranslation } from 'react-i18next';
import { GeoLocation } from '../global-tagger.dto';
import { setLocation, setSelectedPlot, setShowRoofSegments } from '../global-tagger.actions';

interface Properties {
    location: GeoLocation;
    isLoading: boolean;
    setLocation: any;
    setShowRoofSegments: any;
    setSelectedPlot: any;
}

function Search(props: Properties) {
    const {
        location,
        isLoading,
        setLocation,
        setShowRoofSegments,
        setSelectedPlot,
    } = props;

    const [address, setAddress] = useState('');
    const [geocoder, setGeocoder] = useState<google.maps.Geocoder>();

    const loader = useGoogleMapsApiLoader();

    const { t } = useTranslation();

    useEffect(() => {
        console.debug('Search', location);
        loadGeocoder();

        console.debug('NODE ENV', process.env.NODE_ENV);
    }, []);

    useEffect(() => {
        setAddress(location.address);
        setSelectedPlot('');
    }, [location]);

    async function loadGeocoder() {
        const { Geocoder } = (await loader.importLibrary('geocoding')) as google.maps.GeocodingLibrary;
        setGeocoder(new Geocoder());
    }

    async function handleChangeAddress(e: any) {
        console.debug('handleChangeAddress', address, location.address);

        if ((e.key === 'Enter' || e.type === 'click') && geocoder !== undefined) {
            const geocoderResponse: google.maps.GeocoderResponse = await geocoder.geocode({ address });
            console.debug('address.geocoderResponse', geocoderResponse);

            if (geocoderResponse.results.length > 0) {
                setLocation({
                    lat: geocoderResponse.results[0].geometry.location.lat(),
                    lng: geocoderResponse.results[0].geometry.location.lng(),
                    address: geocoderResponse.results[0].formatted_address,
                });
            }
        }
    }

    return (
        <Fragment>
            <Grid xs={7}>
                <Input
                    placeholder={t('Search')}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    onKeyDown={(e) => handleChangeAddress(e)}
                    startDecorator={<SearchIcon />}
                    sx={{ flexGrow: 1 }}
                    disabled={isLoading}
                />
            </Grid>
            <Grid xs={1}>
                <Button variant="solid" color="primary" onClick={(e) => handleChangeAddress(e)} disabled={isLoading}>
                    {t('Search')}
                </Button>
            </Grid>

            <Grid xs={4}>
                <Checkbox
                    label={t('Display Roofs')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setShowRoofSegments(e.target.checked === true)}
                />
            </Grid>
        </Fragment>
    );
}

const mapStateToProps = function (state: any) {
    return {
        location: state.tagger.location,
        isLoading: state.tagger.isLoading,
    };
};

const mapDispatchToProps = function (dispatch: any) {
    return {
        setLocation: (location: GeoLocation) => dispatch(setLocation(location)),
        setShowRoofSegments: (showRoofSegments: boolean) => dispatch(setShowRoofSegments(showRoofSegments)),
        setSelectedPlot: (selectedPlot: string) => dispatch(setSelectedPlot(selectedPlot)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);

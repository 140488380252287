import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router';
import { OnsiteDataPoint } from './onsite.dto';
import { loadOnsiteData } from './onsite.actions';
import { connect } from 'react-redux';
import { CircularProgress } from '@mui/joy';
import CreateTenderPage from 'features/tenders/CreateTenderPage';

interface Properties {
    formData: { [reference: string]: OnsiteDataPoint };
    isLoading: boolean;
    loadOnsiteData: (reference: string) => void;
}

function OnsiteTenderFormPage(props: Properties) {
    const { isLoading, loadOnsiteData } = props;

    const { reference } = useParams();

    useEffect(() => {
        if (reference) {
            loadOnsiteData(reference);
        }
    }, [reference]);

    return (
        <Fragment>
            {isLoading ? (
                <CircularProgress />
            ) : (
                <CreateTenderPage />
            )}
        </Fragment>
    );
}

const mapStateToProps = function (state: any) {
    return {
        formData: state.onsite.formData,
        isLoading: state.onsite.isLoading,
    };
};

const mapDispatchToProps = function (dispatch: any) {
    return {
        loadOnsiteData: (reference: string) => dispatch(loadOnsiteData(reference)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnsiteTenderFormPage);

import { API_BASE_URL, PV_CALCULATOR_API_BASE_URL, QA_PV_CALCULATOR_API_BASE_URL } from 'appConfig';
import { httpClient } from '../../infrastructure/admi-client/http-client';
import { ExtraLoadProfileDto, PVResultDto, RoofSegmentDto } from './pv-calculator.dto';

export const admiPVCalculatorService = {
    async doPVOptimization(
        roof_segments: RoofSegmentDto[],
        optimizer: string,
        load_profile: string,
        extra_load_profiles: ExtraLoadProfileDto[],
        funding_rate: number,
        usage: number | null,
        limit_100kwp: boolean,
        target_production?: number,
        battery_kwp_factor?: number,
        latitude?: number,
        longitude?: number,
        useQA = false,
        sum_wb_hu?: number,
        floor_area?: number,
    ) {
        const response = await httpClient.post<PVResultDto>(
            '/v1/pv-optimization',
            {
                roof_segments,
                optimizer,
                load_profile,
                extra_load_profiles,
                funding_rate,
                usage,
                limit_100kwp,
                target_production,
                battery_kwp_factor,
                latitude,
                longitude,
                sum_wb_hu,
                floor_area,
            },
            {
                baseURL: useQA ? QA_PV_CALCULATOR_API_BASE_URL : PV_CALCULATOR_API_BASE_URL,
                headers: {
                    // TODO: This API will in the future be done completely within the backend.
                    // Then, change the API key
                    'X-API_KEY': '9i90tgioufnqwh58sdg8yhajdnrh2ht4',
                },
            },
        );

        return response.data;
    },

    async getRoofSegments(tag: string): Promise<[RoofSegmentDto[], number, [number, number]]> {
        const response = await httpClient.get<any>('/v1/pv-info', {
            params: {
                tag,
            },
            baseURL: API_BASE_URL,
        });

        return [
            response.data.map(
                (row: any) =>
                    ({
                        kwp: (row.kw_17 * 400) / 280 + row.kw_21_7,
                        kwh_kwp: row.kwh_kwp,
                        modarea: row.modarea,
                        roof_pitch: row.neigung,
                        direction: row.richtung,
                        building_id: row.geb_id,
                    }) as RoofSegmentDto,
            ),
            response.data[0]?.sum_wb_hu,
            response.data[0]?.center,
        ];
    },
};

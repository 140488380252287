export enum Optimizer {
    ROI = 'roi',
    FULL_ROOF = 'full_roof',
    RHEINISCHES_REVIER_FUNDING = 'rheinisches_revier_funding',
}

export enum LoadProfile {
    SCHULE = 'SCHULE',
    BAUHOF = 'BAUHOF',
    BUECHEREI = 'BUECHEREI',
    FEUERWEHR = 'FEUERWEHR',
    FREIBAD = 'FREIBAD',
    FRIEDHOF = 'FRIEDHOF',
    HALLENBAD = 'HALLENBAD',
    JUGENDZENTRUM = 'JUGENDZENTRUM',
    KINDERGARTEN = 'KINDERGARTEN',
    KLAERWERK = 'KLAERWERK',
    MUSEUM = 'MUSEUM',
    PUMPWERK = 'PUMPWERK',
    SPORTPLATZ_AN_SCHULE = 'SPORTPLATZ_AN_SCHULE',
    SPORTPLATZ_OHNE_SCHULE = 'SPORTPLATZ_OHNE_SCHULE',
    VERWALTUNGSGEBAEUDE = 'VERWALTUNGSGEBAEUDE',
    WASSERWERK = 'WASSERWERK',
    WOHNHEIM_FUER_GEFLUECHTETE = 'WOHNHEIM_FUER_GEFLUECHTETE',
}

export enum LoadProfileType {
    HEAT_PUMP = 'heat_pump',
    CUSTOM = 'custom',
}

export interface HeatPumpLoadProfileDto {
    type: LoadProfileType.HEAT_PUMP;
    annual_heat_demand: number;
    annual_cop: number;
}

export interface CustomLoadProfileDto {
    type: LoadProfileType.CUSTOM;
    values: number[];
}

export type ExtraLoadProfileDto = HeatPumpLoadProfileDto | CustomLoadProfileDto;

export interface RoofSegmentDto {
    kwp: number;
    kwh_kwp: number;
    modarea: number;
    roof_pitch: number;
    direction: number;
    building_id: string;
    sum_wb_hu?: number;
    center?: [number, number];
}

export interface PVResultDto {
    design_kwp: number;
    design_kwh_per_year_ac: number;
    battery_capacity: number;
    usage: number;
    initial_investment_incl_funding: number;
    initial_investment: number;
    balance_30_years: number;
    rate_of_return: number;
    profit_loss_accumulated: number[];
    amortization_years: number | null;
    co2_savings: number;
    trees: number;
    ratio_own_use: number;
}

import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Building, Plot, RealEstate } from '../global-tagger.dto';
import {
    Grid,
    Select,
    Option,
    Alert,
    Input,
    CircularProgress,
    Sheet,
    Typography,
    Box,
    FormLabel,
    FormControl,
    Button,
    Modal,
    ModalClose,
} from '@mui/joy';
import { SaveAlt } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
    admiPVCalculatorService,
} from 'features/pv-calculator/admi-pv-calculator.service';
import SalesChart from 'features/sales/SalesChart';
import { generateColorHashFromString } from 'common/generateColorHashFromString';
import { pickTextColorBasedOnBackgroundColor } from 'common/pickTextColorBasedOnBackgroundColor';
import { LoadProfile, Optimizer, PVResultDto, RoofSegmentDto } from 'features/pv-calculator/pv-calculator.dto';
import PlotImage from 'features/real-estate/components/PlotImage';
import { formatToGermanNumber } from 'common/toGermanNumber';
import useScreenshot from 'infrastructure/image/useScreenshot';
import SalesResult from 'features/sales/SalesResult';
import { admiRealEstateService } from 'features/real-estate/admi-real-estate.service';

interface Properties {
    realEstate: RealEstate;
    selectedPlot: string;
    highlightedBuilding: string;
    useQA: boolean;
    useNewUsageModel: boolean;
}

function PVAnalysis(props: Properties) {
    const { realEstate, selectedPlot, highlightedBuilding, useQA, useNewUsageModel } = props;

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [optimizer, setOptimizer] = useState<Optimizer>(Optimizer.ROI);
    const [loadProfile, setLoadProfile] = useState<LoadProfile>(LoadProfile.VERWALTUNGSGEBAEUDE);
    const [fundingRate, setFundingRate] = useState<number>(0);
    const [usage, setUsage] = useState<number | null>(null);

    const [isSalesPrintOpen, setIsSalesPrintOpen] = useState<boolean>(false);

    const [result, setResult] = useState<PVResultDto | null>(null);
    const [fullRoofResult, setFullRoofResult] = useState<PVResultDto | null>(null);

    const [plot, setPlot] = useState<Plot>();

    const [errorMessage, setErrorMessage] = useState<string>('');

    const debounceRef = useRef<NodeJS.Timeout>();

    const salesPrintRef = useRef(null);

    const screenshot = useScreenshot();

    useEffect(() => {
        console.debug('RealEstateData.realEstate', realEstate);
        if (selectedPlot !== '' && realEstate !== undefined && realEstate.plots.length > 0) {
            loadPVPotential();
        }
    }, [realEstate, optimizer, selectedPlot, loadProfile, fundingRate, usage, useQA, useNewUsageModel]);

    // @todo change this method to consume the PV Calculator through our API
    async function loadPVPotential() {
        console.debug('loadPVPotential', realEstate);
        setErrorMessage('');
        setIsLoading(true);
        setResult(null);

        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }

        debounceRef.current = setTimeout(async () => {
            try {
                const roofSegments: RoofSegmentDto[] = [];

                for (const plot of realEstate.plots) {
                    if (plot.code !== selectedPlot) {
                        continue;
                    }

                    for (const building of plot.buildings) {
                        if (building.roof) {
                            for (const roofSegment of building.roof.roofSegments) {
                                console.debug('loadPVPotential', building.code, roofSegment);
                                // some roof segmenets do not have solar potential
                                if (roofSegment.roofSegmentSolarPotential === undefined) {
                                    continue;
                                }

                                roofSegments.push({
                                    kwp: roofSegment.roofSegmentSolarPotential.normalizedKwp,
                                    kwh_kwp: roofSegment.roofSegmentSolarPotential.kwhPerKwp,
                                    modarea: roofSegment.area,
                                    roof_pitch: roofSegment.pitchDegrees,
                                    direction: roofSegment.direction360Degrees,
                                    building_id: building.code,
                                });
                            }

                            console.debug('loadPVPotential.segment', roofSegments);
                        }
                    }

                    setPlot(plot);
                }

                const { lat, lng } = realEstate.locationSearch;
                const floorArea = useNewUsageModel && plot ? admiRealEstateService.getBuildingsFloorArea(plot) : undefined;

                if (roofSegments.length > 0) {
                    const response = await admiPVCalculatorService.doPVOptimization(
                        roofSegments,
                        optimizer,
                        loadProfile,
                        [],
                        fundingRate / 100,
                        usage,
                        false,
                        undefined,
                        undefined,
                        lat,
                        lng,
                        useQA,
                        undefined,
                        floorArea,
                    );
                    setResult(response);

                    const fullRoofResponse = await admiPVCalculatorService.doPVOptimization(
                        roofSegments,
                        Optimizer.FULL_ROOF,
                        loadProfile,
                        [],
                        0,
                        usage,
                        false,
                        undefined,
                        undefined,
                        lat,
                        lng,
                        useQA,
                        undefined,
                        floorArea,
                    );
                    setFullRoofResult(fullRoofResponse);

                    setIsLoading(false);
                }
            } catch (e: any) {
                console.error('loadPVPotential', e);
                setIsLoading(false);
                setErrorMessage(`There was an error while attempting to process the PV potential: ${e.message}`);
            }
        }, 1000);
    }

    function getBackgroundColor(building: Building, plot: Plot): object {
        const bgColor = generateColorHashFromString(`plot_${plot.code}`);
        const color = pickTextColorBasedOnBackgroundColor(bgColor, '#ffffff', '#000000');

        return {
            backgroundColor: building.code === highlightedBuilding ? `#${bgColor}` : 'transparent',
            color: building.code === highlightedBuilding ? color : '#000000',
        };
    }

    async function downloadImage() {
        if (salesPrintRef.current === null) {
            return;
        }

        const canvas: HTMLCanvasElement = await screenshot.screenshot(salesPrintRef.current, {
            top: 0,
            right: 0,
            useCORS: true,
        });

        const link = document.createElement('a');
        link.download = `${plot?.name}_${plot?.code}.png`.replaceAll(' ', '_');
        link.href = canvas.toDataURL('image/png');
        link.click();
    }

    return (
        <Fragment>
            <Grid xs={12} direction="row">
                {errorMessage && (
                    <Alert variant="solid" color="danger">
                        {errorMessage}
                    </Alert>
                )}
            </Grid>

            {selectedPlot !== '' ? (
                <Grid xs={12} container direction="row" spacing={2}>
                    <Grid xs={12} container direction="row" spacing={1}>
                        <Grid xs={3} direction="column">
                            <FormControl required>
                                <FormLabel>{t('Optimizer')}</FormLabel>
                                <Select
                                    placeholder={t('Optimizer')}
                                    value={optimizer}
                                    onChange={(e, value: any) => setOptimizer(value)}
                                >
                                    {Object.values(Optimizer).map((o: Optimizer) => (
                                        <Option key={o} value={o}>
                                            {t(o)}
                                        </Option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid xs={4} direction="column">
                            <FormControl required>
                                <FormLabel>{t('Load Profile')}</FormLabel>
                                <Select
                                    placeholder={t('Load Profile')}
                                    value={loadProfile}
                                    onChange={(e, value: any) => setLoadProfile(value)}
                                >
                                    {Object.values(LoadProfile).map((lp: LoadProfile) => (
                                        <Option key={lp} value={lp}>
                                            {t(lp)}
                                        </Option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid xs={2} direction="column">
                            <FormControl required>
                                <FormLabel>{t('Funding')}</FormLabel>
                                <Input
                                    type="number"
                                    value={fundingRate}
                                    onChange={(e) => setFundingRate(parseFloat(e.target.value))}
                                    endDecorator="%"
                                />
                            </FormControl>
                        </Grid>

                        <Grid xs={3} direction="column">
                            <FormControl>
                                <FormLabel>{t('Usage')}</FormLabel>
                                <Input
                                    type="number"
                                    value={usage ?? ''}
                                    onChange={(e) => setUsage(parseInt(e.target.value))}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid xs={12} container direction="row" justifyContent="right">
                        <Button onClick={() => setIsSalesPrintOpen(true)} disabled={isLoading}>
                            {t('Generate Sales Print')}
                        </Button>
                    </Grid>

                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <Sheet sx={{ height: '100%', overflow: 'auto', p: 4 }}>
                            <Grid xs={12} container direction="row" spacing={2}>
                                {result && (
                                    <Grid xs={12} container direction="column" spacing={0.5}>
                                        <Grid>
                                            <Typography level="h2">{t('PV Calculation Details')}</Typography>
                                        </Grid>

                                        <Grid>
                                            <strong>Usage:</strong> {result.usage.toFixed(2)} kWh
                                        </Grid>

                                        <Grid>
                                            <strong>Design kWp:</strong> {result.design_kwp.toFixed(2)}
                                        </Grid>

                                        <Grid>
                                            <strong>Design kWh/a (AC):</strong>{' '}
                                            {result.design_kwh_per_year_ac.toFixed(2)}
                                        </Grid>

                                        <Grid>
                                            <strong>Initial investment incl. funding:</strong>{' '}
                                            {result.initial_investment_incl_funding.toFixed(2)} €
                                        </Grid>

                                        <Grid>
                                            <strong>Balance after 30 years:</strong>{' '}
                                            {result.balance_30_years.toFixed(2)} €
                                        </Grid>

                                        <Grid>
                                            <strong>Total return per €:</strong>{' '}
                                            {result.rate_of_return.toFixed(2)}
                                        </Grid>

                                        <Grid>
                                            <strong>CO2 savings:</strong> {result.co2_savings.toFixed(2)} t
                                        </Grid>

                                        <Grid>
                                            <strong>Trees:</strong> {result.trees}
                                        </Grid>

                                        <Grid>
                                            <strong>Profit/loss accumulated:</strong>
                                        </Grid>

                                        <Grid>
                                            {result.profit_loss_accumulated.map((n) => n.toFixed(2)).join(', ')}
                                        </Grid>

                                        <Box sx={{ mt: 5 }}>
                                            <SalesChart
                                                variant={result.profit_loss_accumulated}
                                                width={600}
                                                height={400}
                                            />
                                        </Box>
                                    </Grid>
                                )}

                                <Typography level="h2">{t('Real Estate Details')}</Typography>

                                <Grid xs={12} container direction="column" spacing={0}>
                                    <Typography level="h3">{t('Plot')}</Typography>

                                    <Grid>
                                        <strong>{t('ID')}:</strong> {plot?.code}
                                    </Grid>
                                    <Grid>
                                        <strong>{t('Name')}:</strong> {plot?.name}
                                    </Grid>
                                </Grid>

                                <Grid xs={12} container direction="column">
                                    <Typography level="h3">{t('Buildings')}</Typography>

                                    {plot?.buildings.map((building: Building) => (
                                        <Grid key={building.code} style={getBackgroundColor(building, plot)}>
                                            <Grid>
                                                <strong>{t('ID')}:</strong> {building.code}
                                            </Grid>
                                            {building.name && (
                                                <Grid>
                                                    <strong>{t('Name')}:</strong> {building.name}
                                                </Grid>
                                            )}
                                            <Grid>
                                                <strong>{t('Function')}:</strong> {building.buildingFunction.name}
                                            </Grid>

                                            {building.roof && (
                                                <Grid xs={12} container direction="column" spacing={1}>
                                                    <Grid>
                                                        <strong>{t('Roof Area')}:</strong> {building.roof?.area} m²
                                                    </Grid>

                                                    <Grid>
                                                        <Typography level="title-sm">{t('Roof Segments')}:</Typography>
                                                    </Grid>
                                                    {building.roof.roofSegments.map((segment, index) => (
                                                        <Grid key={segment.code}>
                                                            <Grid>
                                                                <strong>{t('Area')}:</strong> {segment.area} m²
                                                            </Grid>
                                                            <Grid>
                                                                <strong>{t('Pitch')}:</strong> {segment.pitchDegrees}°
                                                            </Grid>
                                                            <Grid>
                                                                <strong>{t('Direction')}:</strong>{' '}
                                                                {segment.direction360Degrees}°
                                                            </Grid>
                                                            <Grid>
                                                                <strong>{t('Solar Potential')}:</strong>{' '}
                                                                {segment.roofSegmentSolarPotential?.normalizedKwp.toFixed(
                                                                    2,
                                                                )}{' '}
                                                                kWp
                                                            </Grid>
                                                            <Grid>
                                                                <strong>{t('Kwh per Kwp')}:</strong>{' '}
                                                                {segment.roofSegmentSolarPotential?.kwhPerKwp.toFixed(
                                                                    2,
                                                                )}{' '}
                                                                kWp
                                                            </Grid>
                                                            <Grid>
                                                                <strong>{t('Yearly Energy')}:</strong>{' '}
                                                                {segment.roofSegmentSolarPotential?.yearlyEnergyDcKwh.toFixed(
                                                                    2,
                                                                )}{' '}
                                                                kWh
                                                            </Grid>
                                                            {index < building.roof.roofSegments.length - 1 && (
                                                                <Grid>-</Grid>
                                                            )}
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            )}

                                            <Grid>---</Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Sheet>
                    )}
                </Grid>
            ) : (
                <Grid xs={12} direction="row">
                    <Alert color="primary" variant="soft">
                        &lt;-- {t('Select a plot to see the PV potential')}
                    </Alert>
                </Grid>
            )}

            <Modal
                aria-labelledby={t('Sales Print')}
                open={isSalesPrintOpen}
                onClose={() => setIsSalesPrintOpen(false)}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 10000,
                }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 1366,
                        minWidth: 1366,
                        boxShadow: 'lg',
                        borderRadius: 'md',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />

                    {plot && (
                        <div className="sales sales_count1">
                            <div id="objects" ref={salesPrintRef}>
                                <h3>{plot.name}</h3>
                                <article>
                                    <div className="section_img">
                                        <div className="estimatedEnergyUse">
                                            {!usage ? 'Geschätzter' : 'Gemessener'} Stromverbrauch:{' '}
                                            {formatToGermanNumber(result?.usage, 0)} kWh
                                        </div>
                                        <PlotImage uuid={plot?.code} />
                                    </div>

                                    <div className="section_variants">
                                        {result && (
                                            <SalesResult result={result} fundingRate={fundingRate} optimized={true} />
                                        )}

                                        {fullRoofResult && (
                                            <SalesResult result={fullRoofResult} fundingRate={0} optimized={false} />
                                        )}
                                    </div>
                                </article>
                            </div>

                            <div style={{ float: 'right', paddingBottom: 10 }}>
                                <Button variant="soft" onClick={() => downloadImage()} startDecorator={<SaveAlt />}>
                                    {t('Save Image')}
                                </Button>
                            </div>
                        </div>
                    )}
                </Sheet>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = function (state: any) {
    return {
        realEstate: state.tagger.realEstate,
        selectedPlot: state.tagger.selectedPlot,
        useQA: state.featureFlags.useQAPVCalculator,
        useNewUsageModel: state.featureFlags.useNewUsageModel,
        highlightedBuilding: state.tagger.highlightedBuilding,
    };
};

export default connect(mapStateToProps)(PVAnalysis);

import { Dispatch } from 'redux';
import { LocationSearch, GeoLocation, RealEstate } from './global-tagger.dto';
import { admiGlobalTaggerService } from './admi-global-tagger.service';

export const TYPES = {
    SET_LOCATION: 'SET_LOCATION',

    LOAD_REAL_ESTATE_DATA: 'LOAD_REAL_ESTATE_DATA',

    LOAD_REAL_ESTATE_DATA_REQUESTED: 'LOAD_REAL_ESTATE_DATA_REQUESTED',
    LOAD_REAL_ESTATE_DATA_SUCCEEDED: 'LOAD_REAL_ESTATE_DATA_SUCCEEDED',
    LOAD_REAL_ESTATE_DATA_FAILED: 'LOAD_REAL_ESTATE_DATA_FAILED',

    SET_SELECTED_PLOT: 'SET_SELECTED_PLOT',

    SET_HIGHLIGHTED_BUILDING: 'SET_HIGHLIGHTED_BUILDING',

    SET_SHOW_ROOF_SEGMENTS: 'SET_SHOW_ROOF_SEGMENTS',

    SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
};

export const loadRealEstateData = (dataSearch: LocationSearch) => async (dispatch: Dispatch) => {
    dispatch(loadRealEstateDataRequested(dataSearch));
    console.debug('loadRealEstateDataRequested.dataSearch', dataSearch);

    try {
        const realEstate = await admiGlobalTaggerService.loadRealEstateData(dataSearch);
        dispatch(loadRealEstateDataSucceeded(dataSearch, realEstate));
    } catch (e) {
        let errorMessage = '';
        if (typeof e === 'string') {
            errorMessage = e;
        } else if (e instanceof Error) {
            errorMessage = e.message;
        }
        console.error('loadRealEstateDataFailed.error', e, errorMessage);

        dispatch(loadRealEstateDataFailed(dataSearch, errorMessage));
    }
};

export function loadRealEstateDataRequested(dataSearch: LocationSearch) {
    return { type: TYPES.LOAD_REAL_ESTATE_DATA_REQUESTED, dataSearch };
}

export function loadRealEstateDataSucceeded(dataSearch: LocationSearch, realEstate: RealEstate) {
    return {
        type: TYPES.LOAD_REAL_ESTATE_DATA_SUCCEEDED,
        dataSearch,
        realEstate,
    };
}

export function loadRealEstateDataFailed(dataSearch: LocationSearch, errorMessage: string) {
    return { type: TYPES.LOAD_REAL_ESTATE_DATA_FAILED, dataSearch, errorMessage };
}

export function setLocation(location: GeoLocation) {
    return { type: TYPES.SET_LOCATION, location };
}

export function setSelectedPlot(selectedPlot: string) {
    return { type: TYPES.SET_SELECTED_PLOT, selectedPlot };
}

export function setHighlightedBuilding(highlightedBuilding: string) {
    return { type: TYPES.SET_HIGHLIGHTED_BUILDING, highlightedBuilding };
}

export function setShowRoofSegments(showRoofSegments: boolean) {
    return { type: TYPES.SET_SHOW_ROOF_SEGMENTS, showRoofSegments };
}

export function setErrorMessage(errorMessage: string) {
    return { type: TYPES.SET_ERROR_MESSAGE, errorMessage };
}

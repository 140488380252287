import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup } from '@mui/joy';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { ExtraLoadProfileDto, HeatPumpLoadProfileDto, LoadProfileType } from '../pv-calculator.dto';
import HeatPumpLoad from './HeatPumpLoad';
import CustomLoad from './CustomLoad';

export default function ExtraLoadContainer(props: { onChange: (loadProfiles: ExtraLoadProfileDto[]) => void }) {
    const { t } = useTranslation();

    const [extraLoadProfiles, setExtraLoadProfiles] = useState<ExtraLoadProfileDto[]>([]);

    const addLoadProfile = (type: LoadProfileType) => {
        let newProfile: ExtraLoadProfileDto;
        switch (type) {
            case LoadProfileType.HEAT_PUMP:
                newProfile = {
                    type: type,
                    annual_heat_demand: 0,
                    annual_cop: 0,
                } as HeatPumpLoadProfileDto;
                break;
            case LoadProfileType.CUSTOM:
                newProfile = {
                    type: type,
                    values: [],
                };
                break;
        }

        setExtraLoadProfiles([...extraLoadProfiles, newProfile]);
    };
    const deleteLoadProfile = (i: number) => {
        extraLoadProfiles.splice(i, 1);
        setExtraLoadProfiles([...extraLoadProfiles]);
    };
    const updateLoadProfile = (i: number, newProfile: ExtraLoadProfileDto) => {
        extraLoadProfiles[i] = newProfile;
        setExtraLoadProfiles([...extraLoadProfiles]);
    };

    useEffect(() => {
        props.onChange(extraLoadProfiles);
    }, [extraLoadProfiles]);

    return (
        <>
            <ButtonGroup color="neutral" variant="solid" size="sm">
                <Button startDecorator={<Add />} onClick={() => addLoadProfile(LoadProfileType.HEAT_PUMP)}>
                    {t('Heat pump')}
                </Button>
                <Button startDecorator={<Add />} onClick={() => addLoadProfile(LoadProfileType.CUSTOM)}>
                    {t('From CSV')}
                </Button>
            </ButtonGroup>

            {extraLoadProfiles.map((loadProfile, i) => (
                <React.Fragment key={i}>
                    {loadProfile.type === LoadProfileType.HEAT_PUMP && (
                        <HeatPumpLoad
                            onChange={(changedProfile) => updateLoadProfile(i, changedProfile)}
                            onDelete={() => deleteLoadProfile(i)}
                        />
                    )}

                    {loadProfile.type === LoadProfileType.CUSTOM && (
                        <CustomLoad
                            onChange={(changedProfile) => updateLoadProfile(i, changedProfile)}
                            onDelete={() => deleteLoadProfile(i)}
                        />
                    )}
                </React.Fragment>
            ))}
        </>
    );
}

import React, { useEffect, useRef, useState } from 'react';
import { Button, FormControl, FormLabel, Input, Select, Typography, Option, Alert, Stack, Switch, Slider } from '@mui/joy';
import { SaveAlt } from '@mui/icons-material';
import { useTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux';

import { admiPVCalculatorService } from './admi-pv-calculator.service';
import { ExtraLoadProfileDto, LoadProfile, Optimizer, PVResultDto } from './pv-calculator.dto';
import SalesResult from 'features/sales/SalesResult';
import { formatToGermanNumber } from 'common/toGermanNumber';
import PlotImage from 'features/real-estate/components/PlotImage';
import useScreenshot from 'infrastructure/image/useScreenshot';
import { admiRealEstateService } from 'features/real-estate/admi-real-estate.service';
import { admiGlobalTaggerService } from 'features/global-tagger/admi-global-tagger.service';
import { Layers } from 'features/global-tagger/global-tagger.dto';
import ExtraLoadContainer from './components/ExtraLoadContainer';

interface Results {
    optimizedResult: PVResultDto;
    fullRoofResult: PVResultDto;
    projectName: string;
    tagName: string;
    fundingRate: number;
    optimizer: Optimizer;
}

function PVCalculatorErrorBox(props: { error: any }) {
    const { t } = useTranslation();

    const translatePVError = (err: any) => {
        // Is it a PV-calculator specific error?
        if (err.detail && err.detail.error_code) {
            switch (err.detail.error_code) {
                case 'NO_SUITABLE_ROOF_SEGMENTS': return t('No suitable roof segments found. This could also occur if the entered tag is not available.');
                case 'NO_PLACEMENT_STRATEGY_APPLICABLE': return t('No placement found that fulfills all constraints (e.g. roof is large enough).');
                case 'NOT_WITHIN_OPTIMIZATION_CONSTRAINTS': return t('The optimizer could not find a design that matches all the criteria. Please check the inputs (e.g. too small usage).');
                default: return `${err.detail.description} (${err.detail.error_code})`;
            }
        }
        
        return JSON.stringify(err);
    };

    return (
        <Alert color="danger">
            {translatePVError(props.error)}
        </Alert>
    );
}

const mapStateToProps = function (state: any) {
    return {
        useQA: state.featureFlags.useQAPVCalculator,
        useNewUsageModel: state.featureFlags.useNewUsageModel,
    };
};
const connector = connect(mapStateToProps);

enum OptimizerMode { Auto, Manual }

function PVCalculatorPage(props: ConnectedProps<typeof connector>) {
    const { t } = useTranslation();
    const [projectName, setProjectName] = useState<string>('');
    const [tagName, setTagName] = useState<string>('');
    const [optimizer, setOptimizer] = useState<Optimizer>(Optimizer.ROI);
    const [loadProfile, setLoadProfile] = useState<LoadProfile>(LoadProfile.SCHULE);
    const [fundingRate, setFundingRate] = useState<number>(0);
    const [productionOptimizerMode, setProductionOptimizerMode] = useState<OptimizerMode>(OptimizerMode.Auto);
    const [targetProduction, setTargetProduction] = useState<number>(0.0);
    const [batteryOptimizerMode, setBatteryOptimizerMode] = useState<OptimizerMode>(OptimizerMode.Auto);
    const [batteryKwpFactor, setBatteryKwpFactor] = useState<number>(1.0);
    const [usage, setUsage] = useState<number | null>(null);
    const [extraLoadProfiles, setExtraLoadProfiles] = useState<ExtraLoadProfileDto[]>([]);
    const [constrainFullRoofTo100KWp, setConstrainFullRoofTo100KWp] = useState<boolean>(false);

    const [results, setResults] = useState<Results | null>(null);
    const [loading, setLoading] = useState(false);
    const [resultError, setResultError] = useState<any | null>(null);
    
    const salesPrintRef = useRef(null);
    const screenshot = useScreenshot();

    // When switch to manual, use the values from the result
    useEffect(() => {
        if (productionOptimizerMode === OptimizerMode.Manual && results) {
            setTargetProduction(results.optimizedResult.design_kwh_per_year_ac);
        }
    }, [productionOptimizerMode])
    useEffect(() => {
        if (batteryOptimizerMode === OptimizerMode.Manual && results) {
            setBatteryKwpFactor(results.optimizedResult.battery_capacity / results.optimizedResult.design_kwp);
        }
    }, [batteryOptimizerMode]);

    const submit = async (event: any) => {
        event.preventDefault();

        setLoading(true);
        setResultError(null);

        const [roofSegments, sum_wb_hu, center] = await admiPVCalculatorService.getRoofSegments(tagName);
        if (!roofSegments) {
            console.error('No roof segments found for tag', tagName);
            return;
        }

        if (fundingRate === null) {
            console.error('Missing required fields');
            return;
        }

        let floorArea: number | undefined = undefined;
        if (props.useNewUsageModel && center) {
            const [lat, lng] = center;
            const realEstate = await admiGlobalTaggerService.loadRealEstateData({ location: { address: "", lat: lat, lng: lng }, radius: 20, layers: [Layers.SOLAR_POTENTIAL] });
            if (realEstate.plots.length > 0) {
                const plot = realEstate.plots[0];
                floorArea = admiRealEstateService.getBuildingsFloorArea(plot);
            }
        }

        try {
            const [optimizedResult, fullRoofResult] = await Promise.all([
                // Calculate selected config
                admiPVCalculatorService.doPVOptimization(
                    roofSegments,
                    optimizer,
                    loadProfile,
                    extraLoadProfiles,
                    fundingRate / 100,
                    usage,
                    constrainFullRoofTo100KWp,
                    productionOptimizerMode === OptimizerMode.Manual ? targetProduction : undefined,
                    batteryOptimizerMode === OptimizerMode.Manual ? batteryKwpFactor : undefined,
                    undefined,
                    undefined,
                    props.useQA,
                    sum_wb_hu,
                    floorArea,
                ),

                // Calculate full roof as comparison
                admiPVCalculatorService.doPVOptimization(
                    roofSegments,
                    Optimizer.FULL_ROOF,
                    loadProfile,
                    extraLoadProfiles,
                    0,
                    usage,
                    constrainFullRoofTo100KWp,
                    undefined,
                    batteryOptimizerMode === OptimizerMode.Manual ? batteryKwpFactor : undefined,
                    undefined,
                    undefined,
                    props.useQA,
                    sum_wb_hu,
                    floorArea,
                ),
            ]);

            setResults({ optimizedResult, fullRoofResult, projectName, tagName, fundingRate, optimizer });
        } catch (e: any) {
            setResultError(e);
        }

        setLoading(false);
    };

    const downloadImage = async () => {
        if (salesPrintRef.current === null || results === null) {
            return;
        }

        const canvas: HTMLCanvasElement = await screenshot.screenshot(salesPrintRef.current, {
            top: 0,
            right: 0,
            useCORS: true,
        });

        const link = document.createElement('a');
        link.download = `${results.tagName}.png`.replaceAll(' ', '_');
        link.href = canvas.toDataURL('image/png');
        link.click();
    };

    return (
        <form onSubmit={submit}>
            <Stack spacing={1}>
                <Typography level="h3">{t('PV Calculator')}</Typography>

                <FormControl required>
                    <FormLabel>{t('Project name (only used as the sales print title)')}</FormLabel>
                    <Input value={projectName} onChange={(e) => setProjectName(e.target.value)} />
                </FormControl>

                <FormControl required>
                    <FormLabel>{t('Tag name')}</FormLabel>
                    <Input value={tagName} onChange={(e) => setTagName(e.target.value)} />
                </FormControl>

                <FormControl required>
                    <FormLabel>{t('Optimizer')}</FormLabel>
                    <Select onChange={(e, value: any) => setOptimizer(value)}>
                        {Object.values(Optimizer).map((o: Optimizer) => (
                            <Option key={o} value={o}>
                                {t(o)}
                            </Option>
                        ))}
                    </Select>
                </FormControl>

                <FormControl required>
                    <FormLabel>{t('Building type')}</FormLabel>
                    <Select onChange={(e, value: any) => setLoadProfile(value)}>
                        {Object.values(LoadProfile).map((lp: LoadProfile) => (
                            <Option key={lp} value={lp}>
                                {t(lp)}
                            </Option>
                        ))}
                    </Select>
                </FormControl>

                <FormControl required>
                    <FormLabel>{t('Funding rate (0 - 100)')}</FormLabel>
                    <Input
                        type="number"
                        value={fundingRate}
                        onChange={(e) => setFundingRate(parseFloat(e.target.value))}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>{t('Base usage (kWh) - estimated if not provided')}</FormLabel>
                    <Input type="number" value={usage ?? ''} onChange={(e) => setUsage(parseInt(e.target.value))} />
                </FormControl>

                {props.useQA && <>
                    <FormLabel>{t('Additional consumers (not included in usage above)')}</FormLabel>
                    <ExtraLoadContainer onChange={setExtraLoadProfiles} />
                </>}

                {optimizer !== Optimizer.FULL_ROOF && <>
                    <Switch
                        checked={productionOptimizerMode === OptimizerMode.Manual}
                        onChange={(e) => setProductionOptimizerMode(e.target.checked ? OptimizerMode.Manual : OptimizerMode.Auto)}
                        endDecorator={t('Manually optimize annual target production')}
                        sx={{alignSelf: "start"}}
                    />
                    {productionOptimizerMode === OptimizerMode.Manual && 
                        <FormControl>
                            <FormLabel>{t('Annual target production (kWh/a)')}</FormLabel>
                            <Input type="number" value={targetProduction} onChange={(e) => setTargetProduction(parseInt(e.target.value))} />
                        </FormControl>
                    }
                </>}

                <Switch
                    checked={batteryOptimizerMode === OptimizerMode.Manual}
                    onChange={(e) => setBatteryOptimizerMode(e.target.checked ? OptimizerMode.Manual : OptimizerMode.Auto)}
                    endDecorator={t('Manually optimize battery capacity (for the funding optimizer, \'auto\' still means the fixed factors)')}
                    sx={{alignSelf: "start"}}
                />
                {batteryOptimizerMode === OptimizerMode.Manual &&
                    <FormControl>
                        <FormLabel>{t('Battery size factor (only fill if deviating)')}</FormLabel>
                        <Slider
                            step={0.1}
                            min={0}
                            max={2}
                            marks
                            valueLabelDisplay="on"
                            value={batteryKwpFactor}
                            onChange={(e, value) => setBatteryKwpFactor(value as number)}
                        />
                    </FormControl>
                }

                <Switch
                    checked={constrainFullRoofTo100KWp}
                    onChange={(e) => setConstrainFullRoofTo100KWp(e.target.checked)}
                    endDecorator={t('Constrain PV system to 100 kWp')}
                    sx={{alignSelf: "start"}}
                />

                <Button type="submit" loading={loading}>
                    {t('Calculate')}
                </Button>

                {resultError && <PVCalculatorErrorBox error={resultError} />}

                {results && 
                    <div className="sales sales_count1">
                        <div id="objects" ref={salesPrintRef}>
                            <h3>{results.projectName}</h3>
                            <article>
                                <div className="section_img">
                                    <div className="estimatedEnergyUse">
                                        {!usage ? 'Geschätzter' : 'Gemessener'} Stromverbrauch:{' '}
                                        {formatToGermanNumber(results.optimizedResult.usage, 0)} kWh
                                    </div>
                                    <PlotImage uuid={results.tagName} />
                                </div>

                                <div className="section_variants">
                                    <SalesResult result={results.optimizedResult} fundingRate={results.fundingRate} optimized={results.optimizer !== "full_roof"} />
                                    <SalesResult result={results.fullRoofResult} fundingRate={0} optimized={false} />
                                </div>
                            </article>
                        </div>

                        <div style={{ float: 'right', paddingBottom: 10 }}>
                            <Button variant="soft" onClick={() => downloadImage()} startDecorator={<SaveAlt />}>
                                {t('Save Image')}
                            </Button>
                        </div>
                    </div>
                }
            </Stack>
        </form>
    );
}

export default connector(PVCalculatorPage);

/* eslint @typescript-eslint/no-use-before-define: ["off", { "allowNamedExports": true }] */
import { useEffect, useState } from 'react';
import { httpClient,  } from '../../infrastructure/admi-client/http-client';

export default function useCalculatorData() {
    const [calculatorData, setCalculatorData]: any = useState({});

    useEffect(() => {
        const url = `/v1/sales_input?time=${new Date().getTime()}"`;
        httpClient.get(url).then((response) => setCalculatorData(response.data));
    }, []);

    return calculatorData;
}
